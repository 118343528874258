import { Card, CardContent, Tab, Tabs } from '@mui/material'
import { Box } from '@mui/system'
import { useState, useEffect } from 'react'
import CompanyForm from './CompanyForm'
import PasswordForm from './PasswordForm'
import PersonalForm from './PersonalForm'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import FormSkeletonWrapper from '../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import { parseErrors, replaceNullValues } from './../../../services/utils'
import { toast } from 'react-toastify'
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import useAuth from "../../../hooks/useAuth"
import axios from 'axios'
import BankAccountsTable from './BankAccountsTable'

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

export default function Profile() {
    const { t } = useTranslation()

    const { setAuth } = useAuth()
    const axiosPrivate = useAxiosPrivate()

    const [loading, setLoading] = useState(true)

    const [user, setUser] = useState(null)

    const [value, setValue] = useState(0)

    const [countries, setCountries] = useState([])

    const passwordInitialFormState = {
        password: '',
        password_confirmation: '',
        old_password: ''
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleUpdatePassword = (values, { resetForm, setFieldError }) => {
        axiosPrivate.post('/auth/password/', values)
            .then(() => {
                toast.success(t('Your password has been updated'))
                resetForm()
            })
            .catch(({ response }) => {
                if (response?.status === 401) {
                    localStorage.removeItem('user')
                    return setAuth({})
                }

                const errors = parseErrors(response)

                if (typeof errors === 'object') {
                    for (let i in errors) {
                        setFieldError(errors[i].field, t(errors[i].message), false)
                    }
                } else if (typeof errors === 'string') {
                    toast.error(t(errors))
                } else {
                    toast.error(t('Something went wrong'))
                }
            })
    }

    useEffect(() => {
        const fetchData = () => {
            axios.all([
                axiosPrivate.get('/merchant/countries/'),
                axiosPrivate.get('/merchant/me/')
            ])
                .then(axios.spread((...responses) => {
                    const countriesResponse = responses[0]
                    const merchantResponse = responses[1]

                    const country_list = countriesResponse?.data?.data
                        .sort((a, b) => (a.name_es > b.name_es) ? 1 : ((b.name_es > a.name_es) ? -1 : 0))

                    setCountries(
                        country_list.map((c) => ({
                            id: c.code,
                            name: c.name_es
                        }))
                    )

                    setUser(replaceNullValues({
                        ...merchantResponse.data.data,
                        language: String(merchantResponse.data.data.account_config.language).toUpperCase()
                    }))
                }))
                .catch(({ response }) => {
                    if (response?.status === 401) {
                        localStorage.removeItem('user')
                        return setAuth({})
                    }

                    const errors = parseErrors(response)

                    if (typeof errors === 'string') {
                        toast.error(t(errors))
                    } else {
                        toast.error(t('Something went wrong'))
                    }
                })
                .finally(() => setLoading(false))
        }

        fetchData()
    }, [t, setAuth, axiosPrivate])

    return (
        <>
            <Card>
                <CardContent>
                    {
                        loading ? <FormSkeletonWrapper /> : (
                            <>
                                <Box sx={{
                                    borderBottom: 1,
                                    borderColor: 'divider',
                                    width: {
                                        xs: 'calc(100vw - 80px)',
                                        sm: 'calc(100vw - 330px)'
                                    },
                                    overflow: 'hidden'
                                }}>
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="Account info"
                                        variant="scrollable"
                                        allowScrollButtonsMobile
                                    >
                                        <Tab label={t('Personal info')} {...a11yProps(0)} />
                                        <Tab label={t('Company info')} {...a11yProps(1)} />
                                        <Tab label={t('Bank accounts')} {...a11yProps(2)} />
                                        {/* <Tab label={t('Two-Factor Authentication')} {...a11yProps(2)} /> */}
                                        <Tab label={t('Security')} {...a11yProps(3)} />
                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}>
                                    <PersonalForm
                                        initialFormState={user}
                                        countries={countries}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <CompanyForm
                                        initialFormState={user}
                                        countries={countries}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <BankAccountsTable
                                        initialFormState={user}
                                    />
                                </TabPanel>
                                {/* <TabPanel value={value} index={2}>
                                    <TotpForm totpEnabled={false} />
                                </TabPanel> */}
                                <TabPanel value={value} index={3}>
                                    <PasswordForm
                                        initialFormState={passwordInitialFormState}
                                        handleSubmit={handleUpdatePassword}
                                    />
                                </TabPanel>
                            </>
                        )}
                </CardContent>
            </Card>
        </>
    )
}
