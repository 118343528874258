import { Box, Grid, Stack } from "@mui/material"
import { Form, Formik } from "formik"
import { useTranslation } from 'react-i18next'

import ButtonWrapper from "../../FormUI/ButtonWrapper/ButtonWrapper"
import SubmitWrapper from "../../FormUI/SubmitWrapper/SubmitWrapper"

import SearchIcon from '@mui/icons-material/Search'
import UndoIcon from '@mui/icons-material/Undo'
import SelectWrapper from "../../FormUI/SelectWrapper/SelectWrapper"
import SelectApiWrapper from "../../FormUI/SelectWrapper/SelectApiWrapper"
import { status } from "../../../enums/PaymentMethods"

export default function Filters({
    handleOnSearch,
    handleReset,
    countries = [],
    methods = []
}) {
    const { t } = useTranslation()

    const initialFormState = {
        name: 'all',
        country: 'all',
        status: 'all'
    }

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            onSubmit={handleOnSearch}
        >
            {({ handleSubmit }) => (<Form>
                <Grid container spacing={2}>

                    {countries.length && (<Grid item xs={12} md={2}>
                        <SelectApiWrapper
                            name="country"
                            label={t('Country')}
                            options={countries}
                            size="small"
                        />
                    </Grid>)}

                    {methods.length && (<Grid item xs={12} md={2}>
                        <SelectApiWrapper
                            name="name"
                            label={t('Method')}
                            options={[
                                { id: 'all', name: t('All') }, ...methods
                            ]}
                            size="small"
                        />
                    </Grid>)}

                    <Grid item xs={12} md={2}>
                        <SelectWrapper
                            name="status"
                            label={t('Status')}
                            options={{
                                'all': 'All', ...status
                            }}
                            size="small"
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <SubmitWrapper startIcon={<SearchIcon />}>{t('Search')}</SubmitWrapper>
                            <ButtonWrapper
                                onClick={() => {
                                    handleReset()
                                }}
                                variant="text"
                                startIcon={<UndoIcon />}>{t('Reset')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>)}
        </Formik>
    )
}
