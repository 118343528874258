import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import Pagination from '../../Pagination/Pagination'
import TableActions from '../../TableUI/TableActions'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import TableBackdrop from '../../TableUI/TableBackdrop'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import DatetimeValue from '../../TableUI/DatetimeValue';

export default function UsersTable({
    data,
    pages = 1,
    page = 1,
    loading = false,
    handleOnPageChange,
    handleResetPassword
}) {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const handleNavigate = (view) => {
        navigate(view)
    }

    return (
        <>
            <Paper sx={{
                width: {
                    xs: 'calc(100vw - 80px)',
                    sm: 'calc(100vw - 330px)'
                }, overflow: 'hidden'
            }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('Email address')}</TableCell>
                                <TableCell>{t('User type')}</TableCell>
                                <TableCell align="right">{t('Status')}</TableCell>
                                <TableCell align="center">{t('Created at')}</TableCell>
                                <TableCell align="center">{t('Last login')}</TableCell>
                                <TableCell align="center">&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.length ? data.map((row) => (
                                <TableRow
                                    key={row.email}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.email}
                                    </TableCell>
                                    <TableCell>{t(row.user_type_)}</TableCell>
                                    <TableCell align="right">{t(row.status_)}</TableCell>
                                    <TableCell align="center">
                                        <DatetimeValue date={row.created_at} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <DatetimeValue date={row.last_login} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <TableActions actions={[
                                            {
                                                id: `edit-${row.id}`,
                                                handleClick: () => { handleNavigate(`edit/${row.id}`) },
                                                icon: <EditIcon />,
                                                title: t('Edit')
                                            },
                                            {
                                                id: `resetPassword-${row.id}`,
                                                handleClick: () => handleResetPassword(row.id),
                                                icon: <VpnKeyIcon />,
                                                title: t('Reset password')
                                            }
                                        ]} />
                                    </TableCell>
                                </TableRow>
                            )) : <TableRow>
                                {
                                    loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                                }
                            </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />
        </>
    )
}
