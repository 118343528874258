import { Typography } from '@mui/material'
import React from 'react'

export default function MethodCommission({
    fixed = 0,
    fixed_currency,
    percent = 0
}) {
    const roundedPercent = Math.round(percent * 100) / 100
    const fee_fixed = fixed_currency ? new Intl.NumberFormat('es-CL', { style: 'currency', currency: fixed_currency }).format(fixed) : ''
    const fee_percent = roundedPercent ? `${roundedPercent}%` : ''
    const plus = fee_fixed && fee_percent ? '+' : ''

    return (
        <Typography noWrap>
            {fee_fixed} {plus} {fee_percent}
        </Typography>
    )
}
