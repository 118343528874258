import api from '../api'
import useAuth from './useAuth'


const useRefreshToken = () => {
    const { auth, setAuth } = useAuth()

    const refresh = async () => {
        const response = await api.post('/merchant/login/refresh/', {
            refresh: auth?.user?.refresh
        })

        setAuth(prev => {
            const { user } = prev

            return { ...prev, user: { ...user, access: response.data.data.access } }
        })

        return response.data.data.access
    }

    return refresh;
}

export default useRefreshToken
