import { useState, useEffect, useCallback } from 'react'
import { Button, Grid } from '@mui/material'
import { Link } from 'react-router-dom'

import DescriptionIcon from '@mui/icons-material/Description'
import AddIcon from '@mui/icons-material/Add'
import { Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Filters from './Filters'
import AppFilters from '../../AppFilters'
import UsersTable from './UsersTable'

import { toast } from 'react-toastify'

import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import useAuth from "../../../hooks/useAuth"
import { LoadingButton } from '@mui/lab'
import PageTitle from '../../Misc/PageTitle';


export default function UsersList() {
    const { t } = useTranslation()

    const [fetchingData, setFetchingData] = useState(false)

    const { setAuth } = useAuth()

    const axiosPrivate = useAxiosPrivate()

    const [params, setParams] = useState({})

    const [loading, setLoading] = useState(true)

    const [data, setData] = useState([])

    const [meta, setMeta] = useState({
        total_pages: 1,
        current_page: 1
    })

    const handleOnSearch = values => {
        setLoading(true)

        setParams(values)
    }

    const handleOnPageChange = (e, page) => {
        setLoading(true)

        setMeta({ ...meta, current_page: page })
        setParams({ ...params, page: page })
    }

    const handleExport = () => {
        setFetchingData(true)

        axiosPrivate.get('/merchant/users/export/', { params: params })
            .then(response => {
                const binaryData = []

                binaryData.push(response.data)

                let anchor = document.createElement('a')

                anchor.href = window.URL.createObjectURL(new Blob(binaryData, { type: "text/csv" }))

                anchor.download = 'users.csv'

                anchor.click()
            })
            .catch(() => {
                toast.error(t('Something went wrong'))
            })
            .finally(() => setFetchingData(false))
    }

    const retrieveData = useCallback(() => {
        axiosPrivate.get('/merchant/users/', { params: params })
            .then(response => {
                const { meta, data } = response.data;

                setMeta(meta)

                setData(data)
            })
            .catch(({ response }) => {
                if (response?.status === 401) {
                    localStorage.removeItem('user')
                    return setAuth({})
                }

                toast.error(t('Something wen wrong'))
            })
            .finally(() => setLoading(false))
    }, [params, t, axiosPrivate, setAuth])

    const handleResetPassword = (userId) => {
        return axiosPrivate.post(`/merchant/users/${userId}/reset-password/`, {})
            .then(() => {
                toast.success(t('Password has been reset'))
            })
            .catch(({ response }) => {
                if (response?.status === 401) {
                    localStorage.removeItem('user')
                    return setAuth({})
                }
            })
    }

    useEffect(() => {
        const loadData = () => {
            retrieveData()
        }

        loadData()
    }, [params, retrieveData])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Users')} />

                    <Grid container spacing={2} mt={2} mb={2}>
                        <Grid item xs={12}>
                            <Button
                                component={Link}
                                to="create"
                                size="small"
                                variant="contained"
                                startIcon={<AddIcon />}
                                className="base__btn-action"
                            >
                                {t('New')}
                            </Button>

                            <LoadingButton
                                loading={fetchingData}
                                onClick={() => handleExport()}
                                loadingPosition="start"
                                startIcon={<DescriptionIcon />}
                                size="small"
                                className="base__btn-action"
                            >
                                {t('Export')}
                            </LoadingButton>
                        </Grid>
                    </Grid>

                    <AppFilters Filters={<Filters handleOnSearch={handleOnSearch} />} />

                    <UsersTable
                        data={data}
                        pages={meta.total_pages}
                        page={meta.current_page}
                        loading={loading}
                        handleOnPageChange={handleOnPageChange}
                        handleResetPassword={handleResetPassword}
                    />
                </CardContent>
            </Card>
        </>
    )
}
