import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import Pagination from '../../Pagination/Pagination'
import TableBackdrop from '../../TableUI/TableBackdrop'
import DatetimeValue from '../../TableUI/DatetimeValue'
import CurrencyValue from '../../TableUI/CurrencyValue'
import Chip from '@mui/material/Chip'
import { Tooltip } from '@mui/material'

export default function CashoutsTransactionsTable({
    data,
    pages = 1,
    page = 1,
    loading = false,
    handleOnPageChange
}) {
    const { t } = useTranslation()

    return (
        <>
            <Paper sx={{
                width: {
                    xs: 'calc(100vw - 80px)',
                    sm: 'calc(100vw - 330px)'
                }, overflow: 'hidden'
            }}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">{t('Country')}</TableCell>
                                <TableCell>{t('ID')}</TableCell>
                                <TableCell>{t('Merchant ID')}</TableCell>
                                <TableCell align='right'>{t('Amount')}</TableCell>
                                <TableCell>{t('Beneficiary')}</TableCell>
                                <TableCell align="center">{t('Created')}</TableCell>
                                <TableCell align="center">{t('Paid')}</TableCell>
                                <TableCell align="center">{t('Status')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && data.length ? data.map((row) => (
                                <TableRow
                                    key={row.str_payout_id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="center">{row.country}</TableCell>
                                    <TableCell>{row.str_payout_id}</TableCell>
                                    <TableCell>{row.merchant_payout_id}</TableCell>
                                    <TableCell align="right">
                                        {<CurrencyValue amount={row.amount} currency={row.currency} />}
                                    </TableCell>
                                    <TableCell>{row.full_name}</TableCell>
                                    <TableCell align="center">
                                        <DatetimeValue date={row.created_at} />
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.paid_at && (<DatetimeValue date={row.paid_at} />)}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.status === 'created' && (<Chip label={row.status.toUpperCase()} color="primary" variant="outlined" />)}
                                        {row.status === 'paid' && (<Chip label={row.status.toUpperCase()} color="success" variant="outlined" />)}
                                        {row.status === 'in-process' && (<Chip label={row.status.toUpperCase()} color="info" variant="outlined" />)}
                                        {row.status === 'failed' && (
                                            <Tooltip title={row.error_message}><Chip label={row.status.toUpperCase()} color="error" variant="outlined" /></Tooltip>
                                        )}
                                    </TableCell>
                                </TableRow>
                            )) : <TableRow>
                                {
                                    loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                                }
                            </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />
        </>
    )
}
