
import { useState } from 'react';
import { Box } from '@mui/system';
import {
    Avatar,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu
} from '@mui/material';
import { useStyles } from '../HeaderStyles'
import TranslateIcon from '@mui/icons-material/Translate'

export default function LanguageSwitcher({
    handleChangeLanguage
}) {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const supported_languages = [
        {
            code: 'es',
            name: 'Español'
        },
        {
            code: 'en',
            name: 'English'
        },
        {
            code: 'pt',
            name: 'Português'
        },
    ]

    return (
        <Box sx={{ mr: 2 }}>
            <IconButton
                id="notifications-button"
                aria-controls="notifications-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                color="inherit"
                size="small"
            >
                <TranslateIcon />
            </IconButton>
            <Menu
                id="notifications-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'notifications-button',
                }}
            >
                <List className={classes.navlist} component="nav" aria-label="main mailbox folders">
                    {supported_languages.map((item, i) => (
                        <ListItemButton key={i} onClick={() => {
                            handleChangeLanguage(item.code)
                            handleClose()
                        }}>
                            <ListItemIcon>
                                <Avatar className={classes.ulAvatar}>{item.code.toUpperCase()}</Avatar>
                            </ListItemIcon>
                            <ListItemText primary={item.name}></ListItemText>
                        </ListItemButton>
                    ))}
                </List>
            </Menu>
        </Box>
    )
}
