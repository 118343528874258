import { Grid, Stack } from '@mui/material'
import { Formik, Form } from 'formik'
import TextfieldWrapper from '../../FormUI/Textfield/TextfieldWrapper'
import SelectWrapper from '../../FormUI/SelectWrapper/SelectWrapper'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import SubmitWrapper from '../../FormUI/SubmitWrapper/SubmitWrapper';
import CheckboxWrapper from '../../FormUI/CheckboxWrapper/CheckboxWrapper'
import * as Yup from 'yup'


export default function ConfigForm({
    initialFormState,
    handleSubmit
}) {
    const { t } = useTranslation()

    const versions = {
        1: 'v1 (Query String)',
        2: 'v2 (Webhooks)'
    }

    const FORM_VALIDATION = Yup.object().shape({
        webhook_url: Yup.string()
            .url(t('This field must be a valid URL address')),
        notification_email: Yup.string()
            .email(t('You must enter a valid email address'))
    })

    return (
        <>
            <Formik
                initialValues={{
                    ...initialFormState
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={handleSubmit}
            >
                {({ values }) => (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={2}>
                                <SelectWrapper
                                    name="ipn_version"
                                    label={t('IPN version')}
                                    options={versions}
                                />
                            </Grid>

                            <Box width="100%" />

                            <Grid item xs={12}>
                                <TextfieldWrapper
                                    name="webhook_url"
                                    label={t('Notification URL')}
                                    type="text"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <CheckboxWrapper
                                    label={t('Send notification of payments by mail')}
                                    name="ipn_email"
                                />

                                <CheckboxWrapper
                                    label={t('Send email to customers')}
                                    name="send_customer_email"
                                />
                            </Grid>

                            {values.ipn_email ? <Grid item xs={12}>
                                <TextfieldWrapper
                                    name="notification_email"
                                    label={t('Notification email')}
                                    type="text"
                                />
                            </Grid> : ''}

                            <Box width="100%" />

                            <Grid item xs={12}>
                                <Stack direction="row" spacing={2}>
                                    <SubmitWrapper>{t('Save')}</SubmitWrapper>
                                </Stack>
                            </Grid>

                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    )
}