import { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';


export default function AppFilters({ Filters }) {
    const [expanded, setExpanded] = useState(false);

    const { t } = useTranslation();

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Accordion
            style={{ margin: '1.5rem auto' }}
            expanded={expanded === 'filters'}
            onChange={handleChange('filters')}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="filtersbh-content"
                id="filtersbh-header"
            >
                <Typography>
                    {t('Filters')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {Filters}
            </AccordionDetails>
        </Accordion>
    )
}
