import { Chip, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))

export default function ChipWrapper({
    values,
    separator = ','
}) {

    const [items, setItems] = useState([])
    const [plus, setPlus] = useState(false)

    useEffect(() => {
        const elements = String(values).split(separator)
        setPlus(elements.length > 8)
        setItems(elements.slice(0, 8))
    }, [values, separator])

    return (
        <LightTooltip title={values} placement="bottom-start">
            <Stack
                direction="row"
                spacing={1} sx={{
                    maxWidth: '410px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: '#333'
                }}
            >
                {
                    items.map((chip, idx) => (
                        <Chip key={idx} label={chip} size="small" />
                    ))
                }

                {
                    plus ? <Typography variant='h6'>...</Typography> : ''
                }
            </Stack>
        </LightTooltip>
    )
}
