import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import Pagination from '../../Pagination/Pagination'
import TableBackdrop from '../../TableUI/TableBackdrop'
import DatetimeValue from '../../TableUI/DatetimeValue'
import CurrencyValue from '../../TableUI/CurrencyValue'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'

export default function CashoutsTable({
    data,
    pages = 1,
    page = 1,
    loading = false,
    handleOnPageChange
}) {
    const { t } = useTranslation()

    return (
        <>
            <Paper sx={{
                width: {
                    xs: 'calc(100vw - 80px)',
                    sm: 'calc(100vw - 330px)'
                }, overflow: 'hidden'
            }}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">{t('Date')}</TableCell>
                                <TableCell align="right">{t('Initial balance')}</TableCell>
                                <TableCell align="right">{t('Amount')}</TableCell>
                                <TableCell align="right">{t('Current balance')}</TableCell>
                                <TableCell>{t('Details')}</TableCell>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && data.length ? data.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="center">
                                        <DatetimeValue date={row.created_at} />
                                    </TableCell>
                                    <TableCell align="right">
                                        {<CurrencyValue amount={row.opening_balance} currency={row.currency} />}
                                    </TableCell>
                                    <TableCell align="right">
                                        {<CurrencyValue amount={row.amount} currency={row.currency} />}
                                    </TableCell>
                                    <TableCell align="right">
                                        {<CurrencyValue amount={row.amount_balance} currency={row.currency} />}
                                    </TableCell>
                                    <TableCell>{row.details}</TableCell>
                                    <TableCell>
                                        {(parseInt(row.type) === 1 || parseInt(row.type) === 3) && (<ArrowCircleUpIcon sx={{ color: 'green' }} />)}
                                        {(parseInt(row.type) !== 1 && parseInt(row.type) !== 3) && (<ArrowCircleDownIcon sx={{ color: 'red' }} />)}
                                    </TableCell>
                                </TableRow>
                            )) : <TableRow>
                                {
                                    loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                                }
                            </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />
        </>
    )
}
