import { Alert, AlertTitle, Stack } from '@mui/material'

export default function AlertMessage({
    type = 'success',
    message = '...',
    title = false
}) {
    return (
        <Stack spacing={2} direction="row" mt={4} justifyContent="center">
            <Alert severity={type} sx={{
                width: '100%'
            }}>
                {title && (<AlertTitle>{title}</AlertTitle>)}
                {message}
            </Alert>
        </Stack>
    )
}
