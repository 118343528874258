import { useEffect } from 'react'
import UserForm from './UserForm'
import { Card, CardContent } from '@mui/material'
import { useState } from 'react'
import FormSkeletonWrapper from '../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { parseErrors } from '../../../services/utils'
import PageTitle from '../../Misc/PageTitle'
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import useAuth from "../../../hooks/useAuth"


export default function UserEdit() {
    const { t } = useTranslation()

    const { setAuth } = useAuth()
    const axiosPrivate = useAxiosPrivate()

    const navigate = useNavigate()

    const { userId } = useParams()

    const [loading, setLoading] = useState(true)

    const [user, setUser] = useState()

    const handleCancel = () => {
        navigate('/users')
    }

    const handleSubmit = (values, { setFieldError }) => {
        axiosPrivate.put(`/merchant/users/${userId}/`, values)
            .then(() => {
                toast.success(t('The information has been updated'))
                navigate('/users')
            })
            .catch(({ response }) => {
                if (response?.status === 401) {
                    localStorage.removeItem('user')
                    return setAuth({})
                }

                const errors = parseErrors(response)

                if (typeof errors === 'object') {
                    for (let i in errors) {
                        setFieldError(errors[i].field, t(errors[i].message), false)
                    }
                } else if (typeof errors === 'string') {
                    toast.error(t(errors))
                } else {
                    toast.error(t('Something went wrong'))
                }
            })
    }

    const FORM_VALIDATION = Yup.object().shape({
        email: Yup.string()
            .email(t('You must enter a valid email address'))
            .required(t('This field is required')),
        language: Yup.string()
            .required(t('This field is required')),
        user_type: Yup.number()
            .required(t('This field is required'))
    })

    useEffect(() => {
        const getUser = () => {
            axiosPrivate.get(`/merchant/users/${userId}/`)
                .then(({ data: { data } }) => {
                    data.language = String(data.language).toUpperCase()
                    setUser(data)
                })
                .catch(({ response }) => {
                    if (response?.status === 401) {
                        localStorage.removeItem('user')
                        return setAuth({})
                    }

                    toast.error(t('Something went wrong'))
                })
                .finally(() => setLoading(false))
        }

        getUser()
    }, [userId, t, axiosPrivate, setAuth])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Edit user account')} />

                    {
                        loading ? <FormSkeletonWrapper /> :
                            <UserForm
                                isNew={false}
                                handleSubmit={handleSubmit}
                                handleCancel={handleCancel}
                                initialFormState={user}
                                FORM_VALIDATION={FORM_VALIDATION}
                            />
                    }
                </CardContent>
            </Card>
        </>
    )
}
