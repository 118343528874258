import { Navigate, Outlet } from "react-router-dom"
import useAuth from "../../hooks/useAuth"

export default function RequireAuth({
    allowRoles
}) {
    const { auth } = useAuth()

    return (
        auth?.user?.roles?.find(role => allowRoles?.includes(role))
            ? <Outlet />
            : auth?.user
                ? <Navigate to="/" />
                : <Navigate to="/auth" />
    )
}
