import { Divider, Grid, Tooltip, Chip } from '@mui/material'
import { Box } from '@mui/system'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import TextfieldWrapper from '../../FormUI/Textfield/TextfieldWrapper'
import { IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useState } from 'react'


export default function CredentialsForm({
    initialFormState,
    handleRenew
}) {
    const { t } = useTranslation()

    const [open, setOpen] = useState({
        _public_key: false,
        _secret_key: false,
        _service_id: false,
        _old_uuid: false
    })

    const copyTextToClipboard = async (text) => {
        if (!text) {
            return false
        }

        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    return (
        <>
            <Formik
                initialValues={{
                    ...initialFormState
                }}
                enableReinitialize={true}
            >
                {({ values }) => (
                    <Form>
                        <Grid container spacing={2}>
                            <Divider sx={{
                                width: '66.6%'
                            }}>
                                <Chip label={t('Version 2')} />
                            </Divider>

                            <Grid item xs={12} md={8}>
                                <TextfieldWrapper
                                    name="service_id"
                                    type="text"
                                    label={t('Service ID')}
                                    disabled={true}
                                    InputProps={{
                                        endAdornment: <Tooltip
                                            open={open._service_id}
                                            arrow
                                            title={t('Copied')}
                                            placement="left"><IconButton
                                                onClick={() => {
                                                    copyTextToClipboard(values.service_id)
                                                    setOpen({
                                                        _public_key: false,
                                                        _secret_key: false,
                                                        _old_uuid: false,
                                                        _service_id: true
                                                    })
                                                }}
                                            ><ContentCopyIcon /></IconButton></Tooltip>,
                                    }}
                                />
                            </Grid>

                            <Box width="100%" />

                            <Grid item xs={12} md={8}>
                                <TextfieldWrapper
                                    name="old_uuid"
                                    type="text"
                                    label={t('Shared secret')}
                                    disabled={true}
                                    InputProps={{
                                        endAdornment: <Tooltip
                                            open={open._old_uuid}
                                            arrow
                                            title={t('Copied')}
                                            placement="left"><IconButton
                                                onClick={() => {
                                                    copyTextToClipboard(values.old_uuid)
                                                    setOpen({
                                                        _public_key: false,
                                                        _secret_key: false,
                                                        _old_uuid: true
                                                    })
                                                }}
                                            ><ContentCopyIcon /></IconButton></Tooltip>,
                                    }}
                                />
                            </Grid>

                            <Box width="100%" mt={2} mb={2} />

                            {/* <Divider sx={{
                                width: '66.6%'
                            }}>
                                <Chip label={t('Version 3+')} />
                            </Divider>

                            <Grid item xs={12} md={8}>
                                <TextfieldWrapper
                                    name="public_key"
                                    type="text"
                                    label={t('API key')}
                                    disabled={true}
                                    InputProps={{
                                        endAdornment: <Tooltip
                                            open={open._public_key}
                                            arrow
                                            title={t('Copied')}
                                            placement="left"><IconButton
                                                onClick={() => {
                                                    copyTextToClipboard(values.public_key)
                                                    setOpen({
                                                        _public_key: true,
                                                        _secret_key: false,
                                                        _old_uuid: false
                                                    })
                                                }}
                                            ><ContentCopyIcon /></IconButton></Tooltip>,
                                    }}
                                />
                            </Grid>

                            <Box width="100%" /> */}

                            {/* <Grid item xs={12} md={8}>
                                <TextfieldWrapper
                                    name="secret_key"
                                    type="text"
                                    label={t('Shared secret')}
                                    disabled={true}
                                    InputProps={{
                                        endAdornment: <Tooltip
                                            open={open._secret_key}
                                            arrow
                                            title={t('Copied')}
                                            placement="left"><IconButton
                                                onClick={() => {
                                                    copyTextToClipboard(values.secret_key)
                                                    setOpen({
                                                        _public_key: false,
                                                        _secret_key: true,
                                                        _old_uuid: false
                                                    })
                                                }}
                                            ><ContentCopyIcon /></IconButton></Tooltip>,
                                    }}
                                />
                            </Grid>

                            <Box width="100%" />

                            <Grid item xs={12}>
                                <Stack direction="row" spacing={2}>
                                    <ButtonWrapper onClick={handleRenew}>{t('Renew')}</ButtonWrapper>
                                </Stack>
                            </Grid> */}
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    )
}
