import { Divider, Typography } from '@mui/material'
import React from 'react'

export default function ChartHeader({
    title,
    subtitle = ''
}) {
    return (
        <>
            <Typography
                mt={2}
                sx={{
                    fontWeight: 600,
                    textAlign: 'center'
                }}>{title}</Typography>

            <Typography
                sx={{
                    textAlign: 'center',
                    marginBottom: '2rem'
                }}
            >{subtitle ? subtitle : ''}</Typography>

            <Divider variant="middle" />
        </>
    )
}
