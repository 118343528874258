import React from 'react';
import { Button } from '@mui/material';

export default function ButtonWrapper({
    children,
    ...otherProps
}) {
    const configButton = {
        variant: 'contained',
        color: 'primary',
        ...otherProps
    };

    return (
        <Button
            {...configButton}
        >
            {children}
        </Button>
    )
}
