import { Grid } from '@mui/material'
import { Formik, Form } from 'formik'
import TextfieldWrapper from '../../FormUI/Textfield/TextfieldWrapper'
import SelectWrapper from '../../FormUI/SelectWrapper/SelectWrapper'
import SelectApiWrapper from '../../FormUI/SelectWrapper/SelectApiWrapper'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import DateTimePickerWrapper from '../../FormUI/DateTimePickerWrapper/DateTimePickerWrapper'
import { languages } from '../../../enums/Merchant'


export default function PersonalForm({
    initialFormState,
    handleSubmit,
    countries
}) {
    const { t } = useTranslation()

    return (
        <>
            <Formik
                initialValues={{
                    ...initialFormState
                }}
                onSubmit={handleSubmit}
            >
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={2}>
                            <SelectWrapper
                                name="language"
                                label={t('Language')}
                                options={languages}
                                disabled={true}
                            />
                        </Grid>

                        <Grid item xs={12} md={2}>
                            <SelectApiWrapper
                                name="country"
                                label={t('Country')}
                                options={countries}
                                disabled={true}
                                translate={false}
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={12} md={6}>
                            <TextfieldWrapper
                                name="first_name"
                                label={t('First name')}
                                type="text"
                                disabled={true}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextfieldWrapper
                                name="last_name"
                                label={t('Last name')}
                                type="text"
                                disabled={true}
                            />
                        </Grid>

                        <Grid item xs={12} md={8}>
                            <TextfieldWrapper
                                name="email"
                                label={t('Email address')}
                                type="email"
                                disabled={true}
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={12} md={3}>
                            <TextfieldWrapper
                                name="document_number"
                                label={t('Document number')}
                                type="text"
                                disabled={true}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <DateTimePickerWrapper
                                name="birthday"
                                label={t('Birthday')}
                                type="text"
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </>
    )
}
